/**
 * @param {object} promoSelected Promo selected object
 * @returns {boolean} Sticker is show
 */
const getStickerIsShow = (promoSelected) => {
  const isShow = !!promoSelected.stickerImage;
  return isShow;
};

export default getStickerIsShow;
