import moment from 'moment';

/**
 * @param {object} promoSelected Promo selected object
 * @returns {string} Date from init countdown
 */
const getBannerCounterDate = (promoSelected) => {
  let bannerCounterDate = '';
  if (promoSelected.bannerCounterTime) {
    bannerCounterDate = moment()
      .add(promoSelected.bannerCounterTime, 'minutes')
      .add(1, 'seconds').format();
  } else {
    bannerCounterDate = promoSelected.bannerCounterDate;
  }
  return bannerCounterDate;
};

export default getBannerCounterDate;
