/**
 * @param {object} promoSelected Promo selected object
 * @returns {boolean} Banner is show
 */
const getBannerIsShow = (promoSelected) => {
  const isShow = !!promoSelected.bannerTitle;
  return isShow;
};

export default getBannerIsShow;
