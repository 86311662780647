import {
  getUtm,
} from '../../utils';

/**
 * Select a promo, prioritizing the one that comes from query params source utm
 *
 * @param {Array} promos Promos available
 * @returns {object} Promo hight priority selected
 */
const selectPromo = (promos) => {
  let promoSelected = {};
  const utm = getUtm('utm_source');
  if (utm) {
    promos.items.forEach((item) => {
      if (utm === item.fields.source) {
        promoSelected = item.fields;
      }
    });
  }
  if (Object.keys(promoSelected).length === 0) {
    promos.items.forEach((item) => {
      if (item.fields.source === 'organic') {
        promoSelected = item.fields;
      }
    });
  }
  return promoSelected;
};

export default selectPromo;
