import { createClient } from 'contentful';
import {
  getUtm,
} from '../utils';

const isPreview = getUtm('preview');

/**
 * Retrieve entries with search parameters content type
 */
export default class ContentfulService {
  client = createClient({
    space: 'lslyn9ywzpwn',
    environment: process.env.REACT_APP_ENVIRONMENT,
    accessToken: isPreview
      ? process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN_PREVIEW
      : process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    host: isPreview && 'preview.contentful.com',
  });

  /**
   * @param {string} country Pacient country
   * @returns {Array} Entries field from content type
   */
  getPromos = async (country) => {
    const entries = await this.client.getEntries({
      content_type: `quiz${country}Promo`,
      select: 'fields',
    });
    return entries;
  };
}
