/**
 * @param {object} promoSelected Promo selected object
 * @returns {string} Counter type
 */
const getBannerCounterType = (promoSelected) => {
  let bannerCounterType = '';
  if (promoSelected.bannerCounterType) {
    if (promoSelected.bannerCounterType === 'Days and hours') {
      bannerCounterType = 'dh';
    } else if (promoSelected.bannerCounterType === 'Minutes and seconds') {
      bannerCounterType = 'ms';
    }
  }
  return bannerCounterType;
};

export default getBannerCounterType;
