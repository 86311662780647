import getDevicePixelRatio from './getDevicePixelRatio';

/**
 * @param {object} imagesData Images data raw
 * @param {number} width Image width
 * @param {number} height Image height
 * @returns {Array} Empty if not exist or images params
 */
const getImages = (imagesData, width, height) => {
  const devicePixelRatio = getDevicePixelRatio();
  let images = [];
  if (imagesData) {
    images = imagesData.map((imageData) => ({
      src: `${
        imageData.fields.file.url
      }?w=${
        devicePixelRatio * width
      }`,
      alt: imageData.fields.title,
      width,
      height,
    }));
  }
  return images;
};

export default getImages;
