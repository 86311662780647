/**
 * @returns {number} Device pixel ratio
 */
const getDevicePixelRatio = () => {
  let pixelRatio = 1;
  if (window.devicePixelRatio > 1 && window.devicePixelRatio <= 2) {
    pixelRatio = 2;
  } else if (window.devicePixelRatio > 2) {
    pixelRatio = 3;
  }
  return pixelRatio;
};

export default getDevicePixelRatio;
