/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, createContext } from 'react';
import ContentfulService from '../service';
import {
  selectPromo,
  getBannerIsShow,
  getBannerCounterType,
  getBannerCounterDate,
  getStickerIsShow,
} from './promo';
import {
  getImage,
  getImages,
} from '../utils';

export const ContentfulContext = createContext();

/**
 * @param {object} props Props in context
 * @returns {object} Context provider
 */
function ContentfulContextProvider(props) {
  const { children } = props;
  const contentfulService = new ContentfulService();
  const [promo, setPromo] = useState({
    banner: {
      isShow: false,
    },
    sticker: {
      isShow: false,
    },
  });
  const getPromo = async () => {
    try {
      const country = 'Pe';
      const promos = await contentfulService.getPromos(country);
      const promoSelected = selectPromo(promos);
      if (Object.keys(promoSelected).length === 0) {
        throw new Error('Not exist promo');
      }
      const bannerIsShow = getBannerIsShow(promoSelected);
      const stickerIsShow = getStickerIsShow(promoSelected);
      const promoToSet = {
        banner: {
          isShow: bannerIsShow,
        },
        sticker: {
          isShow: stickerIsShow,
        },
      };
      if (bannerIsShow) {
        promoToSet.banner.title = promoSelected.bannerTitle;
        promoToSet.banner.images = getImages(promoSelected.bannerImages, 52, 52);
        promoToSet.banner.imageInTitle = getImage(promoSelected.bannerImageInTitle, 30, 20);
        promoToSet.banner.counterType = getBannerCounterType(promoSelected);
        promoToSet.banner.counterDate = getBannerCounterDate(promoSelected);
        promoToSet.banner.utmContent = promoSelected.bannerUtmContent;
      }
      if (stickerIsShow) {
        promoToSet.sticker.image = getImage(promoSelected.stickerImage, 130, 130);
      }
      setPromo(promoToSet);
    } catch (error) {
      setPromo({
        banner: {
          isShow: false,
        },
        sticker: {
          isShow: false,
        },
      });
    }
  };
  useEffect(() => {
    getPromo();
  }, []);
  return (
    <ContentfulContext.Provider value={{ promo }}>
      { children }
    </ContentfulContext.Provider>
  );
}

export default ContentfulContextProvider;
