import getDevicePixelRatio from './getDevicePixelRatio';

/**
 * @param {object} imageData Image data raw
 * @param {number} width Image width
 * @param {number} height Image height
 * @returns {object} Empty if not exist or image params
 */
const getImage = (imageData, width, height) => {
  const devicePixelRatio = getDevicePixelRatio();
  let image = {};
  if (imageData) {
    image = {
      src: `${
        imageData.fields.file.url
      }?w=${
        devicePixelRatio * width
      }`,
      alt: imageData.fields.title,
      width,
      height,
    };
  }
  return image;
};

export default getImage;
